.App {
  text-align: center;
  align-items: center;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-content {
  flex: 1;
}

.padded-grid {
  width: 100%;
  max-width: min(1200px, 90vw);
  margin: 0 auto;
  display: flex;
  overflow-x: hidden;
}

.left-align {
  text-align: start;
}

.form-style {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.padded-links {
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 3%;
}

.font-medium {
  font-size: medium;
}

.item-centered {
  text-align: center;
}

.responsive-container {
  width: 100%;
  max-width: min(1200px, 90vw);
  margin: 0 auto;
  padding: 0 20px;
  overflow-x: hidden;
}

.home-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.ui.grid {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
}

.ui.image {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .Home h1 {
    font-size: 2em !important;
  }
  
  .home-image-container img {
    max-width: 80% !important;
  }

  .padded-grid {
    padding: 0 10px;
  }

  .ui.items>.item {
    flex-direction: column;
  }

  .ui.items>.item>.image {
    margin: 0 auto 1em !important;
  }

  .form-style {
    padding: 10px;
    width: 95%;
  }

  .ui.form {
    width: 100%;
    padding: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  .Home h1 {
    font-size: 1.5em !important;
  }

  .ui.grid {
    margin: 0 !important;
  }

  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
